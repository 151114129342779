import React, {useEffect} from 'react';

function Landing() {
    const getOS = () => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        if (
            /iPad|iPhone|iPod/.test(userAgent) ||
            (navigator.userAgent.includes('Macintosh') &&
                navigator.maxTouchPoints >= 1)
        ) {
            return 'iOS';
        }

        return 'unknown';
    };

    const openAppStore = () => {
        const theOS = getOS();
        const androidStore =
            'https://play.google.com/store/apps/details?id=com.kompan.kompan';
        const iosStore =
            'https://apps.apple.com/app/kompan-outdoor-fitness/id1493500028';

        if (theOS !== 'unknown') {
            theOS === 'iOS'
                ? location.replace(iosStore)
                : location.replace(androidStore);
        } else {
            location.replace('https://www.kompan.dk/');
        }
    };

    useEffect(() => {
        openAppStore();
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                marginTop: 100,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                flexDirection: 'column',
            }}
        >
            <h1 style={{marginBottom: 20, fontFamily: 'sans-serif'}}>
                Kompan Fitness
            </h1>
        </div>
    );
}

export default Landing;
