import React from 'react';

function Landing() {
    const getOS = () => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        if (
            /iPad|iPhone|iPod/.test(userAgent) ||
            (navigator.userAgent.includes('Macintosh') &&
                navigator.maxTouchPoints >= 1)
        ) {
            return 'iOS';
        }

        return 'unknown';
    };

    return (
        <div
            style={{
                display: 'flex',
                marginTop: 100,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                flexDirection: 'column',
            }}
        >
            <h1
                style={{
                    marginBottom: 20,
                    fontFamily: 'sans-serif',
                    textAlign: 'center',
                }}
            >
                Kompan Fitness ({getOS()})
            </h1>
        </div>
    );
}

export default Landing;
