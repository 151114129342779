import React from 'react';
import ReactDOM from 'react-dom/client';
import Landing from './pages/Landing';
import Test from './pages/Test';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="*" index element={<Landing />} />
                <Route path="qrtest" element={<Test />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
